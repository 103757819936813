<script setup>
import {useLocalePath} from 'vue-i18n-routing'
const localePath = useLocalePath()

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  category: {
    type: Array,
  },
  images: {
    type: Array,
    required: true,
  },
  slug: {
    type: String,
    required: true,
  },
  awards: {
    type: Array,
  },
})
</script>

<template>
  <div class="card-project animate-in-fade">
    <NuxtLink
      :to="localePath(`/projects/${props.slug}`)"
      class="card-link sensible-more">
      <div class="card-top">
        <SharedBasicImage
          :assets="props.images"
          :alternative="props.title"
          :animate="false"
          class="card-image" />
        <div class="category-label-wrapper">
          <div
            class="card-category category-label"
            v-for="(cat, index) in props.category"
            :key="index">
            {{ cat.translation }}
          </div>
        </div>
        <div class="award-wrapper" v-if="props.awards">
          <div
            class="award-item"
            v-if="props.awards[0] && props.awards[0].logo_assets">
            <img :src="props.awards[0].logo_assets[0].src" alt="" />
          </div>
          <div
            class="award-item"
            v-if="props.awards[1] && props.awards[1].logo_assets">
            <img :src="props.awards[1].logo_assets[1].src" alt="" />
          </div>
        </div>
      </div>
      <div class="card-bottom">
        <h3 class="cta cta--dark">
          {{ props.title }}
          <div class="menu-link-btn btn btn--round-small btn--round-dark">
            <svg class="icon icon--btn-round-small">
              <use xlink:href="#icon-arrow"></use>
            </svg>
          </div>
        </h3>
      </div>
    </NuxtLink>
  </div>
</template>

<style lang="scss">
.card-project {
  height: 100%;

  &:hover {
    .card-image {
      img {
        transform: scale(1.025);
      }
    }
  }

  .card-link {
    cursor: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-decoration: none;
  }

  .card-top {
    margin-bottom: pxrem(20);
    position: relative;
  }

  .card-image {
    overflow: hidden;

    img {
      aspect-ratio: 1.6;
      transition: transform $transition-default;
      width: 100%;
      will-change: transition;
    }
  }

  .category-label-wrapper {
    display: inline-flex;
    flex-wrap: wrap;
    left: pxrem(15);
    max-width: 80%;
    position: absolute;
    top: pxrem(15);

    @include mq(md) {
      left: pxrem(20);
      top: pxrem(20);
    }
  }

  .award-wrapper {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    right: pxrem(15);
    position: absolute;
    top: pxrem(15);

    @include mq(md) {
      right: pxrem(20);
      top: pxrem(20);
    }
  }

  .award-item {
    margin-bottom: pxrem(10);
    width: pxrem(40);

    @include mq(xs) {
      width: pxrem(50);
    }

    @include mq(md) {
      width: pxrem(65);
    }

    @include mq(lg) {
      width: pxrem(80);
    }
  }

  .card-category {
    margin-bottom: pxrem(5);
    max-width: 100%;

    &:not(:first-of-type) {
      margin-left: pxrem(5);
    }
  }
}
</style>
